// React and third-parties
import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import NumberFormat from 'react-number-format';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

// Types
import { calculoTipos, aplicacionEstadoTipos } from './pagoTypes';

// Validations
import { validateAplicacion, validatePagoInfo } from './validations';

// Helpers
import { setAbonoFormat, calcularRecargosYDescuentos, calcularPagoPrelacion, calcularPagoPrelacion2 } from './pagoHelpers';
import { waitForElement } from '../../../helpers/waitForElement';

// Components
import SuperCore from '../../core/SuperCore';
import SuperModal from '../../coreModal/SuperModal';
import CurrencyFormatInput from './CurrencyFormatInput';

// Requests
import { getDocumentosPendientesDeCobro, 
    postAplicarPagosDeDocumentosDeCobro, 
    postDocumentosPasswordManual } from './requests';

registerLocale("es", es);

const modal = new SuperCore();

const toastConfig = { position: "bottom-right", hideAfter: 5 };

const setPagoInfoInit = (prelacion) => {
    return {
        fecha: new Date(),
        idMetodoPago: 0,
        importe: 0,
        banco: "",
        numMovimiento: "",
        calculoTipo: prelacion ? calculoTipos.prelacion : calculoTipos.manual,
        saldoAFavorGenerado: 0
    }
};

export default function AplicarPagoModal(props) {

    const { viviendaInfo: { unidad, id_vivienda }, userInfo: { userName, userId },
        enabledPrelacion, metodosPago, onCloseModal, onSuccessfulSubmit } = props;

    const initDateSelected = useRef();
    const currentIdVivienda = useRef(0); currentIdVivienda.current = id_vivienda;

	const [loadingPago, setLoadingPago] = useState(false);
	const [loadingDocumentos, setLoadingDocumentos] = useState(false);
	const [loadingPasswordSubmit, setLoadingPasswordSubmit] = useState(false);
	const [documentosPendientes, setDocumentosPendientes] = useState([]);

	const [saldoAFavor, setSaldoAFavor] = useState(0);
	const [aplicacionEstado, setAplicacionEstado] = useState(aplicacionEstadoTipos.inicial);
	const [pagoInfo, setPagoInfo] = useState(setPagoInfoInit(enabledPrelacion));
	const [passwordText, setPasswordText] = useState("");

    const [headersInfo, setHeadersInfo] = useState([]);
    const [tableStyleInfo, setTableStyleInfo] = useState({ headersInfoMaxWidth: 0, enableConceptShadow: false });

	const metodosPagoFormat = metodosPago.map((metodo) => {
		if(metodo.Id_FormaPago === -1 && saldoAFavor === 0) {
			return { ...metodo, disabled: true };
		} else {
			return metodo;
		}
	});

    const tableUpdate = (() => {

        const dateSelected = pagoInfo.fecha.toLocaleDateString();
        if(dateSelected !== initDateSelected.current) {
            initDateSelected.current = undefined;
            return dateSelected;
        }
        else {
            return "";
        }
    })();

	const loadDocumentosPendientes = useCallback(async (search_id_vivienda) => {

		setLoadingDocumentos(true);

		const { documentosPendientes, saldoAFavor, error } = await getDocumentosPendientesDeCobro(search_id_vivienda);

        if(search_id_vivienda !== currentIdVivienda.current) return;

		if(error) {
			cogoToast.error(error, toastConfig);
		} else {
            const documentosCalculados = calcularRecargosYDescuentos(documentosPendientes, new Date());
			setDocumentosPendientes(documentosCalculados);
			setSaldoAFavor(saldoAFavor);
		}

		setLoadingDocumentos(false);

	}, []);

    const resetForm = useCallback(() => {

        const pagoInfoInit = setPagoInfoInit(enabledPrelacion);
		setPagoInfo(pagoInfoInit);
		setAplicacionEstado(aplicacionEstadoTipos.inicial);
		setSaldoAFavor(0);

        initDateSelected.current = pagoInfoInit.fecha.toLocaleDateString();

	}, [enabledPrelacion]);

    const setTableResizeObserver = () => {

        const tableDiv = document.getElementById("newPayment").getElementsByClassName("table-apply-payment")[0];

        const divObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                const { contentRect, target } = entries[0];
                setTableStyleInfo(({ headersInfoMaxWidth }) => { return { 
                    headersInfoMaxWidth: contentRect ? contentRect.width : headersInfoMaxWidth,
                    enableConceptShadow: target.clientWidth < target.scrollWidth
                }});
            }
        });

        divObserver.observe(tableDiv, { box: "border-box" });

        return divObserver;
    };

	useEffect(() => {
	  
		if(id_vivienda !== 0) {
            resetForm();
			modal.superOpenModal("newPayment");
			loadDocumentosPendientes(id_vivienda);
		} 

	}, [loadDocumentosPendientes, resetForm, id_vivienda]);

    useLayoutEffect(() => {
       
        if(id_vivienda !== 0) {

            const observer = setTableResizeObserver();
            return () => observer.disconnect();
        }

    }, [id_vivienda]);

	const loadSubmitPago = async () => {

		const setSubmitPagosAplicados = async () => {

			const { fecha, saldoAFavorGenerado, calculoTipo, idMetodoPago } = pagoInfo;

			const { error: errorAplicacion } = validateAplicacion(documentosPendientes, aplicacionEstado, calculoTipo, pagoInfo);

			if(errorAplicacion) {
				cogoToast.error(errorAplicacion, toastConfig);
				return false;
			}

            const fechaPago = moment(fecha).tz("GMT").toDate();

			const detalles = documentosPendientes
                .filter((documento) => documento.Aplicado !== 0 || documento.Condonar)
                .map((documento) => {
                    const { Condonar, Importe, Descuento, Recargos, Aplicado } = documento;
                    return { ...documento, Condonacion: Condonar ? (Importe + Recargos - Descuento) - Aplicado : 0 }
                });

			const abonos = detalles.map(({ Id_DocCobro, Aplicado, Saldo, Condonar }) => {
				return setAbonoFormat(Id_DocCobro, Aplicado, fechaPago, idMetodoPago)
            });

			const data = { 
				id_vivienda, fechaRecibo: new Date(), detallecobro: detalles,
				pagoInfo, abonos, saldoAfavor: saldoAFavor + saldoAFavorGenerado,
				userName, userId
			};

			const { created, error: errorSubmit } = await postAplicarPagosDeDocumentosDeCobro(data);
	
			if(errorSubmit) {
				cogoToast.error(errorSubmit, toastConfig);
				return false;
			}
	
			if(created) {
				cogoToast.success("Se creó el recibo de manera correcta.", toastConfig);
                return true;
			}
		};

		setLoadingPago(true);

		const submitted = await setSubmitPagosAplicados();

		setLoadingPago(false);

        if(submitted) {
            modal.superCloseModal("newPayment");
            onSuccessfulSubmit();
        }
	};

	const loadSubmitPassword = async () => {

		setLoadingPasswordSubmit(true);

		const { valid, error } = await postDocumentosPasswordManual(passwordText);

		if (error) {
			cogoToast.error(error, toastConfig);
			setAplicacionEstado(aplicacionEstadoTipos.inicial);
		} 
		
		if(valid) {

            const importeDiff = pagoInfo.importe - documentosPendientes.reduce((total, documento) => total + documento.Saldo, 0);
            const saldoAFavorGenerado = importeDiff > 0 ? importeDiff : 0;
            setPagoInfo(prevInfo => { return { ...prevInfo, saldoAFavorGenerado }});

			setAplicacionEstado(aplicacionEstadoTipos.manual);
		}
		else {
			cogoToast.error("La contraseña es incorrecta.", toastConfig);
		}

		modal.superCloseModal("passwordManual");

		setPasswordText("")
		setLoadingPasswordSubmit(false);
	};

	const setRecargosYDescuentos = (fecha) => {

		const newDocumentosPendientes = calcularRecargosYDescuentos(documentosPendientes, fecha);

		setDocumentosPendientes(newDocumentosPendientes);
		setPagoInfo(prevInfo => { return { ...prevInfo, fecha }});
	};

    const openPasswordModal = () => {

        const passwordInput = document.getElementById("passwordManual").getElementsByTagName("input").namedItem("password");

        modal.superOpenModal("passwordManual");
        setTimeout(() => passwordInput.focus(), 100);
    }

	const setCalculoAplicar = () => {

		const { error } = validatePagoInfo(documentosPendientes, saldoAFavor, pagoInfo);

        if(error) { cogoToast.error(error, toastConfig); return; }

		if(pagoInfo.calculoTipo === calculoTipos.manual) { openPasswordModal(); return; }

		const prelacionInfo = calcularPagoPrelacion2(documentosPendientes, pagoInfo);

        const { saldoAFavorGenerado, documentosOrdenados, headersInfo } = prelacionInfo;

		setPagoInfo(prevInfo => { return { ...prevInfo, saldoAFavorGenerado }});
		setDocumentosPendientes(documentosOrdenados);
		setAplicacionEstado(aplicacionEstadoTipos.prelacion);

        setHeadersInfo(headersInfo);
	};

	const onResetCalculo = () => {

		setAplicacionEstado(aplicacionEstadoTipos.inicial);
		setDocumentosPendientes(prevDocumentos => prevDocumentos.map(documento => 
			{ return { ...documento, Aplicado: 0, checked: false, Condonar: false }; }
		));
	};

	const onSetDocCheck = (index, checked) => {

        setDocumentosPendientes(prevDocumentos => {

            if(!prevDocumentos[index].checked && calculoTotales.Aplicados === pagoInfo.importe) {

                cogoToast.warn("Importe seleccionado alcanzado", toastConfig);
                return prevDocumentos;
            }

            const documentosCopy = [...prevDocumentos];
            documentosCopy[index] = { ...documentosCopy[index], Aplicado: 0, checked };

            if (checked) {

                const rowElement = document.getElementById("newPayment")
                    .getElementsByTagName("tbody")[0]
                    .getElementsByTagName("tr")[index];

                waitForElement(".checked-input", rowElement)
                    .then((element) => { element.focus() });

            }

            return documentosCopy;
        });
	};

	const onSetDocAplicar = (index, aplicarCantidad) => {

        const getApplyCantidad = (aplicar, remaining, saldar) => {

            if(aplicar > remaining) return remaining > saldar ? saldar : remaining;
            
            if(aplicar > saldar) return saldar > remaining ? remaining : saldar;

            return aplicar;
        };

        const setUnappliedAsUnchecked = (documentos) => {
            for (let index = 0; index < documentos.length; index++) {
                const { Aplicado, checked } = documentos[index];
                
                if(checked && Aplicado === 0) 
                    documentos[index] = { ...documentos[index], checked: false }
            }
        };

        setDocumentosPendientes(prevDocumentos => {
            const documentosCopy = [...prevDocumentos];
            const { Aplicado, Importe, Recargos, Descuento, Pagado, Condonar } = documentosCopy[index];
            const totalPreCondonar = Importe + Recargos - Descuento;
            const saldoPreCondonar = totalPreCondonar - Pagado;
            const aplicadoTotal = calculoTotales.Aplicados - Aplicado;
            const cantidadRemaining = pagoInfo.importe - aplicadoTotal;
            const applyCantidad = getApplyCantidad(parseFloat(aplicarCantidad), cantidadRemaining, saldoPreCondonar);
            const applyCondonar = !(Aplicado === saldoPreCondonar && saldoPreCondonar !== 0) && Condonar;

            const newTotal = aplicadoTotal + applyCantidad;
            const reachedImporte = pagoInfo.importe === parseFloat(newTotal.toFixed(2));

            if(reachedImporte) setUnappliedAsUnchecked(documentosCopy);

            const newValues = applyCondonar ? 
                { Condonacion: saldoPreCondonar - applyCantidad , Total: applyCantidad, Saldo: applyCantidad }
                : { Condonacion: 0, Total: totalPreCondonar, Saldo: saldoPreCondonar }

            documentosCopy[index] = { 
                ...documentosCopy[index], 
                Aplicado: applyCantidad,
                Condonar: applyCondonar,
                ...newValues
            }
    
            return documentosCopy;
        });
	};

    const onSetDocCondonar = (index, condonar) => {
        
        setDocumentosPendientes(prevDocumentos => {
            const { Importe, Descuento, Recargos, Pagado, Aplicado, Condonar } = prevDocumentos[index];
            const totalPreCondonar = Importe + Recargos - Descuento;
            const saldoPreCondonar = totalPreCondonar - Pagado;

            if(Aplicado === saldoPreCondonar && !Condonar) return prevDocumentos;

            const documentosCopy = [...prevDocumentos];

            documentosCopy[index] = { 
                ...documentosCopy[index], 
                Condonar: condonar,
                Condonacion: !condonar ?  0 : saldoPreCondonar - Aplicado,
                Total: !condonar ? totalPreCondonar : Aplicado,
                Saldo: !condonar ? saldoPreCondonar : Aplicado
            };

            return documentosCopy;
        });
    };

    const calculoTotales = aplicacionEstado !== aplicacionEstadoTipos.inicial ?
        documentosPendientes.reduce((totales, documento) => {

            const { Importe, Descuento, Recargos, Total,
                Pagado, Saldo, Aplicado, Condonacion, Condonar } = documento;

            if (Aplicado !== 0) {
                totales.Importes += Importe;
                totales.Descuentos += Descuento;
                totales.Recargos += Recargos;
                totales.Totales += Total;
                totales.Pagados += Pagado;
                totales.Saldos += Saldo;
                totales.Aplicados += Aplicado;
            }

            if (Condonar) {
                totales.Condonados += Condonacion;
            }

            return totales;

        }, { Importes: 0, Descuentos: 0, Recargos: 0, Totales: 0, Pagados: 0, Saldos: 0, Aplicados: 0, Condonados: 0 }) : null;

    const DocumentsTableRow =
        <div className="row full table-apply-payment">
            <TablaDocumentosDePago
                loadingDocumentos={loadingDocumentos}
                documentosPendientes={documentosPendientes}
                aplicacionEstado={aplicacionEstado}
                headersInfo={headersInfo}
                condonar={aplicacionEstado === aplicacionEstadoTipos.manual && pagoInfo.saldoAFavorGenerado === 0}
                calculoTotales={calculoTotales}
                tableStyleInfo={tableStyleInfo}
                tableUpdate={tableUpdate}
                onSetDocAplicar={onSetDocAplicar}
                onSetDocCheck={onSetDocCheck}
                onSetDocCondonar={onSetDocCondonar}
            />
        </div>;

	const pagoScreens = [
		<AplicarPagoForm
			unidad={unidad}
			metodosPago={metodosPagoFormat}
			documentosPendientes={documentosPendientes}
			saldoAFavor={saldoAFavor}
			loadingDocumentos={loadingDocumentos}
            calculoTotales={calculoTotales}
			aplicacionActiva={aplicacionEstado !== aplicacionEstadoTipos.inicial}
            enabledPrelacion={enabledPrelacion}
			pagoInfo={pagoInfo}
			setPagoInfo={setPagoInfo}
			onFechaChange={setRecargosYDescuentos}
			onSetCalculo={setCalculoAplicar}
			onResetCalculo={onResetCalculo}
            DocumentsTableRow={DocumentsTableRow}
		/>
	];

	const passwordScreens = [
		<PagoManualPasswordForm
			password={passwordText}
			setPassword={setPasswordText}
			onSubmit={loadSubmitPassword}
		/>
	];

	return (
		<>
			<SuperModal
				id={"newPayment"}
				size={"huge"}
				title={"Aplicar pago"}
				screens={pagoScreens}
				onSubmit={loadSubmitPago}
				loading={loadingPago}
				triggerCloseModal={onCloseModal}
			/>
			<SuperModal
				id={"passwordManual"}
				size={"small"}
				title={"Contraseña de pago manual"}
				lblSubmit={"Aceptar"}
				screens={passwordScreens}
				onSubmit={loadSubmitPassword}
				loading={loadingPasswordSubmit}
				triggerCloseModal={() => setPasswordText("")}
			/>
		</>
	)
};

const PagoManualPasswordForm = (props) => {

	const { password, setPassword, onSubmit } = props;

	return (
		<div className="container">
			<div className="column full">
				<div className="row full">
					<div className="white-space-16"></div>
				</div>
				<div className="row full">
					<div className="input-form-content row full align-center">
						<div className="column label-big">
							<p>
								<b>Contraseña:</b>
							</p>
						</div>
						<div className="column full">
							<input
                                tabIndex="-1"
								className="input input-modals"
								type="password"
								name="password"
								onKeyDown={event => { if(event.key === "Enter") onSubmit(); }}
								value={password}
								onChange={(event) => setPassword(event.target.value)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const AplicarPagoForm = (props) => {

	const { 
		unidad, metodosPago, documentosPendientes, enabledPrelacion,
		aplicacionActiva, pagoInfo, saldoAFavor, loadingDocumentos, calculoTotales,
		setPagoInfo, onFechaChange, onSetCalculo, onResetCalculo,
        DocumentsTableRow
	 } = props;

	const { fecha, idMetodoPago, importe, calculoTipo, banco, numMovimiento, saldoAFavorGenerado } = pagoInfo;
    const { Condonados } = calculoTotales || { Condonados : 0 };

    const saldoTotal = documentosPendientes.reduce((suma, doc) => suma + doc.Saldo, 0);

	return (
		<div className="container">
			<div className="column full">
				<div className="white-space-16"></div>
				<div className="row full" style={{ justifyContent: 'space-between' }}>
					<label className="cont-table-fake width-20">
						<h3 className="tlt-table-fake">Vivienda</h3>
						<div className="cont-input-table-fake">
							<input
								type="text"
								placeholder="Vivienda"
								className="input-modals text-center"
								value={unidad}
								minLength='2'
								maxLength='4'
								disabled={true}
							/>
						</div>
					</label>
					<label className="cont-table-fake width-20">
						<h3 className="tlt-table-fake">Saldo a favor</h3>
						<div className="cont-input-table-fake align-end">
							{!loadingDocumentos ?
								<NumberFormat
									className="input-modals text-right"
									value={saldoAFavor}
									displayType={"number"}
									placeholder={"$"}
									thousandSeparator={true}
									prefix={"$"}
									decimalScale={2}
									fixedDecimalScale={true}
									disabled={true}
								/>
								:
								<input
									className="input-modals text-right"
									value={"--"}
									disabled={true}
								/>
							}
						</div>
					</label>
				</div>
				<div className="white-space-16"></div>
				<div className="column" style={{ justifyContent: 'space-between' }} >
					<div className="row full" style={{ justifyContent: 'space-between' }}>
						<label className="cont-table-fake width-20" >
							<h3 className="tlt-table-fake">Fecha</h3>
							<div className="cont-input-table-fake" >
								<DatePicker
									className="input input-modals"
									name="fechaLinea"
									selected={fecha}
									disabled={aplicacionActiva}
									dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
									placeholderText="Seleccionar fecha"
									locale="es"
									onChange={onFechaChange}
								/>
							</div>
						</label>
						<label className="cont-table-fake width-20" >
							<h3 className="tlt-table-fake">Formas de pago</h3>
							<div className="cont-input-table-fake" >
								<select
									className="input-modals"
									name="formaPagoLinea"
									disabled={aplicacionActiva}
									value={idMetodoPago}
									onChange={(event) => setPagoInfo(prevInfo => { return { ...prevInfo, idMetodoPago: +event.target.value } })}
								>
									<option value="0">Seleccione</option>
									{metodosPago.map((metodo, index) => (
										<option
											key={index}
											value={metodo.Id_FormaPago}
											disabled={metodo.disabled}
										>
											{metodo.Descripcion}
										</option>
									))}
								</select>
							</div>
						</label>
						<label className="cont-table-fake width-20" >
							<h3 className="tlt-table-fake">Importe</h3>
							<div className="cont-input-table-fake" >
                                <CurrencyFormatInput
                                    value={importe}
                                    className={"text-right input input-modals"}
                                    displayType={"number"}
                                    disabled={aplicacionActiva}
                                    onValueChange={!aplicacionActiva ? (value) => setPagoInfo(prevInfo => { return { ...prevInfo, importe: Number(value) }}) : undefined}
                                />
							</div>
						</label>
						<div className="column" style={{ justifyContent: 'space-between' }}>
							<label className="container-radio" style={{ pointerEvents: !aplicacionActiva && enabledPrelacion ? "all" : "none" }}>
                                <input
									type="radio"
									name="prelacion"
									checked={calculoTipo === calculoTipos.prelacion}
									onChange={() => setPagoInfo(prevInfo => { 
										return { ...prevInfo, calculoTipo: calculoTipos.prelacion }
									})}
									disabled={aplicacionActiva || !enabledPrelacion}
								/>
                                <p>Prelación</p>
								<span className="checkmarks"></span>
							</label>
							<label className="container-radio" style={{ pointerEvents: !aplicacionActiva ? "all" : "none" }}>
								<input
									type="radio"
									name="manual"
									checked={calculoTipo === calculoTipos.manual}
									onChange={() => setPagoInfo(prevInfo => { 
										return { ...prevInfo, calculoTipo: calculoTipos.manual }
									})}
									disabled={aplicacionActiva}
								/>
								<p>Manual</p>
								<span className="checkmarks"></span>
							</label>
						</div>
						<div className="btn-position" style={{ justifyContent: "flex-end", minWidth: 110 }}>
							<button
								type="button"
								className="btn btn-primary btn-red-table color-white height-30"
								onClick={!aplicacionActiva ? onSetCalculo : onResetCalculo}
							>
								{!aplicacionActiva ? "Aplicar" : "No Aplicar"}
							</button>
						</div>
					</div>
					<div className="white-space-16"></div>
                    {DocumentsTableRow}
					<div className="white-space-16"></div>
                    <div className='row' style={{ gap: 8 }}>
                        <div className='row full align-start'>
                            <div className="row full align-start">
                                <label className="cont-table-fake width-50" style={{ marginRight: 15 }}>
                                    <h3 className="tlt-table-fake">Banco</h3>
                                    <div className="cont-input-table-fake" >
                                        <input
                                            type="text"
                                            className="input-modals"
                                            minLength='2'
                                            disabled={!aplicacionActiva || (aplicacionActiva && parseInt(idMetodoPago) === -1)}
                                            value={parseInt(idMetodoPago) === -1 ? "Saldo a favor" : banco}
                                            onChange={(event) => setPagoInfo(prevInfo => { return { ...prevInfo, banco: event.target.value } })}
                                        />
                                    </div>
                                </label>
                                <label className="cont-table-fake width-50" >
                                    <h3 className="tlt-table-fake" >{'Nº Movimiento'}</h3>
                                    <div className="cont-input-table-fake" >
                                        <input
                                            type="text"
                                            placeholder=""
                                            className="input-modals"
                                            minLength='2'
                                            disabled={!aplicacionActiva || (aplicacionActiva && parseInt(idMetodoPago) === -1)}
                                            value={parseInt(idMetodoPago) === -1 ? "Saldo a favor" : numMovimiento}
                                            onChange={(event) => setPagoInfo(prevInfo => { return { ...prevInfo, numMovimiento: event.target.value } })}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className='column full justify-end align-start'>
                            {aplicacionActiva && saldoAFavorGenerado > 0 &&
                                <div className="row full align-center justify-end" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>Saldo a favor generado: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        color='green'
                                        value={saldoAFavorGenerado}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }
                            {aplicacionActiva && saldoAFavorGenerado > 0 && saldoAFavor > 0 &&
                                <div className="row full align-center justify-end" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>Nuevo saldo a favor: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        value={saldoAFavor + saldoAFavorGenerado}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            } 
                            {Condonados > 0 &&
                                <div className="row full align-center justify-end" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>A condonar: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        value={Condonados}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }
                            {!loadingDocumentos &&
                                <div className="row full justify-end align-center" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>Total: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        value={saldoTotal}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getAplicacionEstilo = (estado, condonar) =>
    estado === aplicacionEstadoTipos.inicial ? 'inactiva' :
        !condonar ? 'pago-aplicado no-condonar'
            : 'pago-aplicado no-condonar';

const TablaDocumentosDePago = (props) => {

	const { loadingDocumentos, documentosPendientes, aplicacionEstado, condonar, calculoTotales,
		onSetDocAplicar, onSetDocCheck, onSetDocCondonar,
        headersInfo, tableStyleInfo, tableUpdate
    } = props;

    const { headersInfoMaxWidth, enableConceptShadow } = tableStyleInfo;

    const tableRef = useRef();

    const [animationTriggered, setAnimationTriggered] = useState(false);

    const setRowResizeObserver = () => {

        const rowElement = tableRef.current.querySelector('tbody tr');
        const lastTd = Array.from(rowElement.children).slice(-1)[0];

        const tdObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                const { inlineSize } = entries[0].borderBoxSize[0];
                const offset = inlineSize + "px";

                tableRef.current.style.setProperty('--condonar-offset', offset);
            }
        });

        tdObserver.observe(lastTd, { box: "border-box" });

        return tdObserver;
    };

    useEffect(() => {
      
        if(!condonar) return;

        const observer = setRowResizeObserver();
        return () => observer.disconnect();

    }, [condonar]);

    useLayoutEffect(() => {
        
        if(loadingDocumentos) return;

        setAnimationTriggered(false);
        if (tableUpdate) window.requestAnimationFrame(() => setAnimationTriggered(true));

    }, [tableUpdate, loadingDocumentos]);

    useLayoutEffect(() => {

        if (aplicacionEstado !== aplicacionEstadoTipos.inicial) 
            setAnimationTriggered(false);

    }, [aplicacionEstado]);

    const isEstadoAndChecked = (estado, checked) => aplicacionEstado === estado && checked;

    const tableClassName = "full " + (getAplicacionEstilo(aplicacionEstado, condonar)) + (enableConceptShadow ? " enable-shadow" : "");
    const tableRowClassName = (doc) => animationTriggered && (doc.Descuento > 0 || doc.Recargos > 0) ? "cell-update" : "";

	return (
        <table ref={tableRef} className={tableClassName} style={{ zIndex: 0 }}>
			<thead>
				<tr>
					<th>Concepto</th>
                    <th>Mes</th>
                    {/* <th>Prelacion</th> */}
					<th>N° Folio</th>
					<th>Importe</th>
					<th>Dto. PP</th>
					<th>Recargos</th>
                    {condonar &&
                        <th className="weight-bold text-center">
                            Condonar
                        </th>
                    }
					<th className="weight-bold text-center">Total</th>
                    <th className="weight-bold text-center">Pagado</th>
					<th className="weight-bold text-center">Saldo</th>
                    <th className="weight-bold text-center">
                        Aplicar
                    </th>
				</tr>
			</thead>
			<tbody>
				{loadingDocumentos ?
					<tr>
						<td colSpan={10} className="text-center" style={{ borderRight: "unset", boxShadow: "unset" }}>
							<i className="fas fa-spinner fa-spin"></i>
						</td>
					</tr>
					: documentosPendientes.length === 0 ?
						<tr>
							<td colSpan={10} className="text-center">
								Sin datos que mostrar
							</td>
						</tr>
						:
                        <>
                            {documentosPendientes.map((doc, index) => {

                                const headerTitle = headersInfo[index];
                                const manualYChecked = isEstadoAndChecked(aplicacionEstadoTipos.manual, doc.checked);
                                const manualYNotChecked = isEstadoAndChecked(aplicacionEstadoTipos.manual, !doc.checked);

                                return (<React.Fragment key={index}>
                                    {headerTitle && aplicacionEstado === aplicacionEstadoTipos.prelacion &&
                                        <tr>
                                            <td className='documents-header' colSpan={11}>
                                                <div style={{ maxWidth: headersInfoMaxWidth }}>
                                                    {headerTitle}
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    <tr className={tableRowClassName(doc)}>
                                        <td style={{ maxWidth: 250 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "nowrap", gap: 4 }}>
                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                    {doc.Concepto}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="font-mini" style={{ whiteSpace: "nowrap" }}>
                                            {moment(doc.Fecha, "MM-YYYY").locale('es').format("MMM YYYY").replace(".", "")}
                                        </td>
                                        {/* <td className="font-mini">{doc.Prelacion}</td> */}
                                        <td className="font-mini">{doc.NumFolio}</td>
                                        <td className="text-right font-mini">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Importe}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right font-mini">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Descuento}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right font-mini">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Recargos}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        {condonar &&
                                            <td className="text-right color-red" >
                                                <div className='row justify-end align-center'>
                                                    <NumberFormat
                                                        className="font-mini"
                                                        value={doc.Condonacion}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={"$"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                    <label className="container-check">
                                                        <input
                                                            type="checkbox"
                                                            name="checkbox"
                                                            checked={doc.Condonar}
                                                            disabled={(doc.Saldo === doc.Aplicado && !doc.Condonar) || doc.Descuento > 0}
                                                            className="check-option"
                                                            onChange={(e) => onSetDocCondonar(index, e.target.checked)}
                                                        />
                                                        <span className="checkmark" style={{ left: "30%" }}></span>
                                                    </label>
                                                </div>
                                            </td>
                                        }
                                        <td className="text-right">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Total}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Pagado}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Saldo}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td
                                            className={"text-right color-red"}
                                            onDoubleClick={() => manualYNotChecked ? onSetDocCheck(index, true) : undefined}
                                        >
                                            <div style={{ display: "flex", minWidth: manualYChecked ? 140 : undefined, flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 8 }}>
                                                <CurrencyFormatInput
                                                    value={doc.Aplicado}
                                                    className={`text-right
                                                    ${!manualYChecked ? "" : " input input-modals full checked-input "} 
                                                    ${doc.checked ? "weight-bold" : "font-mini"}`
                                                    }
                                                    displayType={!manualYChecked ? "text" : "number"}
                                                    disabled={!manualYChecked}
                                                    onValueChange={aplicacionEstado === aplicacionEstadoTipos.manual ? (value) => onSetDocAplicar(index, value) : undefined}
                                                />
                                                <label
                                                    className="container-check"
                                                    style={aplicacionEstado === aplicacionEstadoTipos.prelacion ? { pointerEvents: "none" } : undefined}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        name="checkbox"
                                                        checked={doc.checked}
                                                        disabled={aplicacionEstado !== aplicacionEstadoTipos.manual}
                                                        className="check-option"
                                                        onChange={aplicacionEstado === aplicacionEstadoTipos.manual ? (e) => onSetDocCheck(index, e.target.checked) : undefined}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                                )
                            })}
                            {calculoTotales &&
                                <tr>
                                    <td>Totales</td>
                                    <td></td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={calculoTotales.Importes}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={calculoTotales.Descuentos}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={calculoTotales.Recargos}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    {condonar &&
                                        <td className="text-right" style={{ paddingInline: 16 }}>
                                            <NumberFormat
                                                className="font-mini"
                                                value={calculoTotales.Condonados}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                    }
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={calculoTotales.Totales}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={calculoTotales.Pagados}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={calculoTotales.Saldos}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right" style={{ paddingInline: 16 }}>
                                        <NumberFormat
                                            className="weight-bold"
                                            value={calculoTotales.Aplicados}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                </tr>
                            }
                        </>
                }
            </tbody>
        </table>
	);
}