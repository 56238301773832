import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from "react-helmet";
import NumberFormat from 'react-number-format';

// Context
import { Consumer } from '../../context';

// Components
import InformesControls from '../../components/controls/InformesControls';
import Header from '../../components/header/header';
import SidebarAdministracion from '../../components/header/sidebarAdministracion';
import PaginadorParent, { paginadorFilter } from '../../components/paginador/PaginadorParent';

// Requests
import { desgloseTipos } from './hooks/useInformesParameters';

const informesInfoInit = () => {
    const todayDate = new Date();

    const pagosFechasDesdeInit = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
    const pagosFechasHastaInit = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

    return {
        informes: [],
        headersInfo: [],
        filters: {
            desglose: 0,
            vacios: 0,
            pagosFechas: { desde: pagosFechasDesdeInit, hasta: pagosFechasHastaInit },
        },
        tableSettings: {
            anclarColumnas: false,
            anclarFilas: true,
            desglosarTotales: true
        }
    }
};

const detalladoFilters = {
    vivsIds: ({ id_vivienda }, values) => values.indexOf(id_vivienda) !== -1,
    prodsIds: ({ id_producto }, values) => values.indexOf(id_producto) !== -1,
    foliosIds: ({ id_docCobro }, values) => {
        const folioIndex = values.indexOf(id_docCobro);
        folioIndex !== -1 && values.splice(folioIndex, 1);
        return folioIndex !== -1;
    },
    pagoEstado: ({ pago, saldo }, value) => value !== 2 ? value !== 1 ?
        true
        : (saldo === 0 || pago > 0) //&& (saldo !== 0 && pago !== 0)
        : saldo > 0
    ,
    pagosFechas: ({ pagos }, { desde, hasta }) => {

        for (let index = 0; index < pagos.length; index++) {
            const fechaPago = moment(pagos[index].fecha);
            const pagoIsBetweenFilter = fechaPago.isBetween(desde, hasta, "days", "[]");

            if (pagoIsBetweenFilter) return true;
        }

        return false;
    }
};

const concentradoFilters = {
    vivsIds: ({ id_vivienda }, values) => values.indexOf(id_vivienda) !== -1,
    vacios: ({ productos }, value) =>
        productos.some(({ pago }) => pago > 0)
};

const applyDetalladoFilters = ({ informes, filters }) => {

    const filtersToApply = Object.entries(filters).reduce((active, [key, value]) => {

        const filter = detalladoFilters[key];

        if (filter !== undefined) {

            const values = key === 'foliosIds' ? [...value] :
                key === 'pagosFechas' ? { desde: moment(value.desde), hasta: moment(value.hasta) }
                    : value;

            active.push({ filter, values });
        }

        return active;

    }, []);

    return informes.filter((informe) =>
        filtersToApply.every(({ filter, values }) =>
            filter(informe, values)))
};

const applyConcentradoFilters = ({ informes, filters }) => {

    const filtersToApply = [];

    if (filters.vivsIds) {
        filtersToApply.push({ filter: concentradoFilters.vivsIds, values: filters.vivsIds })
    }

    if (filters.vacios === 0) {
        filtersToApply.push({ filter: concentradoFilters.vacios, values: filters.vacios })
    }

    return informes.filter((informe) =>
        filtersToApply.every(({ filter, values }) =>
            filter(informe, values)));
};

const initWidthPercentage = 0.8;
const containerWidthPercentage = 0.9;
const initWidthContent = initWidthPercentage * 100;

function Informes(props) {

    const { context, location } = props;
    const { getSubmenuAdminName, submenuAdminName } = context;

    const [informesInfo, setInformesInfo] = useState(informesInfoInit);
    const [tablesMaxHeight, setTablesMaxHeight] = useState(0);
    const [contentWidth, setContentWidth] = useState(initWidthContent + "%");

    const contentRef = useRef();
    const initContentHeight = useRef(null);

    if(initContentHeight.current === null) {
        const contentElement = contentRef.current?.children[0];
        if(contentElement) initContentHeight.current = contentElement.clientHeight;
    }

    useEffect(() => {

        const sidebar = document.getElementById('sidebar');
        const informesTable = contentRef.current.querySelector('#informes-tables');

        const config = { attributes: true };

        const callback = (mutationList, observer) => {

            for (let index = 0; index < mutationList.length; index++) {
                const { attributeName, target } = mutationList[index];
                
                if(attributeName !== 'class') continue;

                if(target.className.includes('active')) {
                    const maxWidth = document.body.clientWidth * initWidthPercentage * containerWidthPercentage;
                    informesTable.style.setProperty("--table-max-width", maxWidth + "px");
                    setContentWidth(initWidthContent + "%");
                    return;
                }
            }

            const maxWidth = document.body.clientWidth * containerWidthPercentage;
            informesTable.style.setProperty("--table-max-width", maxWidth + "px");
            setContentWidth("100%");
        };

        const maxWidth = document.body.clientWidth * initWidthPercentage * containerWidthPercentage;
        informesTable.style.setProperty("--table-max-width", maxWidth + "px");

        const observer = new MutationObserver(callback);

        observer.observe(sidebar, config);

        return () => observer.disconnect();

    }, []);

    useEffect(() => {

        getSubmenuAdminName(location.pathname);

    }, [getSubmenuAdminName, location]);

    const onControlsSizeChange = ({ height }) => {

        const newMaxHeight = initContentHeight.current - height;
        setTablesMaxHeight(newMaxHeight);
    };

    const onFiltersChanged = (filters) => setInformesInfo(prevInfo => {

        const { headersInfo, ...otherFilters } = filters;

        const newInfo = { ...prevInfo };

        const newFilters = { ...newInfo.filters, ...otherFilters };

        Object.keys(newFilters).forEach((key) => newFilters[key] === undefined && delete newFilters[key]);

        newInfo.filters = newFilters;
        if (headersInfo) newInfo.headersInfo = headersInfo;

        return newInfo;
    });

    const onTableSettingsChanged = (settings) => setInformesInfo(prevInfo => {

        const newInfo = { ...prevInfo };
        const newSettings = { ...newInfo.tableSettings, ...settings };

        newInfo.tableSettings = newSettings;

        return newInfo;
    });

    const onInformesChanged = (informes, filters) => setInformesInfo(prevInfo => {

        const { headersInfo, ...otherFilters } = filters;

        const newInfo = { ...prevInfo };

        const newFilters = { ...newInfo.filters, ...otherFilters };

        Object.keys(newFilters).forEach((key) => newFilters[key] === undefined && delete newFilters[key]);

        newInfo.informes = informes;
        newInfo.filters = newFilters;
        newInfo.headersInfo = headersInfo;

        return newInfo;
    });

    const filteredInformes = useMemo(() => {

        return informesInfo.informes.length === 0 ? null
            : informesInfo.filters.desglose === 0 ?
                applyDetalladoFilters(informesInfo)
                : applyConcentradoFilters(informesInfo)

    }, [informesInfo]);

    console.log("Filtered informes: ", filteredInformes);

    return (
        <div className="column" /*admin*/>
            <Helmet>
                <title>{process.env.REACT_APP_NAME} - {submenuAdminName}</title>
            </Helmet>
            <Header
                id='header'
                reload={() => console.log("Add reload")}
                change={() => console.log("Add change")}
                sidebar={true}
                nav={"administracion"}
            />
            <div className="row full">
                <div className="column" id="sidebar">
                    <SidebarAdministracion />
                </div>
                <div id="content"
                    ref={contentRef}
                    className="column align-center" 
                    style={{ width: contentWidth }}
                >
                    <div
                        className="container column"
                        style={{ flex: 1 }}
                    >
                        <InformesControls
                            title={submenuAdminName}
                            excelData={filteredInformes}
                            onSizeChange={onControlsSizeChange}
                            onInformes={onInformesChanged}
                            onFiltersChanged={onFiltersChanged}
                            onTableSettingsChanged={onTableSettingsChanged}
                        />
                        <InformesTableSelector
                            informes={filteredInformes}
                            desglose={informesInfo.filters.desglose}
                            pagosFechas={informesInfo.filters.pagosFechas}
                            headersInfo={informesInfo.headersInfo}
                            tableSettings={informesInfo.tableSettings}
                            tablesMaxHeight={tablesMaxHeight}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumer(Informes);

const InformesTableSelector = (props) => {

    const { informes, desglose, pagosFechas, headersInfo, tableSettings, tablesMaxHeight } = props;

    const setConcentradoTotalesFormat = (cargo = 0, descuento = 0, condonacion = 0, recargo = 0, pago = 0, total = 0) => {
        return { cargo, descuento, condonacion, recargo, pago, total }
    };

    const sumConcentradoTotales = (totales1, totales2) => {

        return setConcentradoTotalesFormat(
            totales1.cargo + totales2.cargo,
            totales1.descuento + totales2.descuento,
            totales1.condonacion + totales2.condonacion,
            totales1.recargo + totales2.recargo,
            totales1.pago + totales2.pago,
            totales1.total + totales2.total
        );
    };

    const [paginadorInfo, setPaginadorInfo] = useState({ page: 1, elementsPerPage: 50 });

    const enabledHeaders = headersInfo ? headersInfo.filter((({ disabled }) => !disabled)) : [];

    const onPagedDataChanged = ({ page, elementsPerPage }) => setPaginadorInfo(prevInfo => {

        const { page: prevPage, elementsPerPage: prevElements } = prevInfo;

        if(page === prevPage && elementsPerPage === prevElements) return prevInfo;

        return { ...prevInfo, page, elementsPerPage };
    })

    const getDetalladoTotalesYPagosActual = (informes) => {

        const fechas = pagosFechas ? { 
            desde: moment(pagosFechas.desde), 
            hasta: moment(pagosFechas.hasta)
        } : null;

        const extraInfo = informes.reduce((info, documento) => {

            const { colTotales, pagosActual } = info;

            const pagoActual = fechas ? documento.pagos.reduce((acum, { fecha, pago }) => {
                const fechaPago = moment(fecha);
                const pagoIsBetweenFilter = fechaPago.isBetween(fechas.desde, fechas.hasta, "days", "[]");

                return pagoIsBetweenFilter ? acum + pago : acum;
            }, 0) : documento.pago;

            pagosActual.push(pagoActual);

            colTotales.Descuentos += documento.descuento;
            colTotales.Condonados += documento.condonacion;
            colTotales.Recargos += documento.recargo;
            colTotales.Importes += documento.cargo;
            colTotales.Pagados += pagoActual; //documento.pago;
            colTotales.Saldos += documento.saldo;

            return info;

        }, {
            colTotales: { Importes: 0, Descuentos: 0, Condonados: 0, Recargos: 0, Pagados: 0, Saldos: 0 },
            pagosActual: []
        });

        extraInfo.pagosActual = paginadorFilter(extraInfo.pagosActual, 
            paginadorInfo.page, paginadorInfo.elementsPerPage);

        return extraInfo;
    }

    const getConcentradoTotales = (informes, headersInfo) => {

        const totales = informes.reduce((totales, concentrado) => {

            const { rowTotales, colTotales } = totales;
            const { productos, id_vivienda, } = concentrado;

            headersInfo.forEach(({ id }) => {
                const producto = productos.find(({ id_producto }) => id_producto === id);
                colTotales[id_vivienda] = sumConcentradoTotales(colTotales[id_vivienda], producto);
                rowTotales[id] = sumConcentradoTotales(rowTotales[id], producto)
            });

            rowTotales['ColTotales'] = sumConcentradoTotales(rowTotales['ColTotales'], colTotales[id_vivienda]);

            return totales;

        }, {
            rowTotales: /* by producto  */
                headersInfo.reduce((totales, { id }) => { return { ...totales, [id]: setConcentradoTotalesFormat() } },
                    { ColTotales: setConcentradoTotalesFormat() }),
            rowPerc: /* by producto */
                headersInfo.reduce((totales, { id }) => { return { ...totales, [id]: 0 } },
                    { ColTotales: 0 }),
            colTotales: /* by vivienda */
                informes.reduce((totales, { id_vivienda }) => { return { ...totales, [id_vivienda]: setConcentradoTotalesFormat() } }, {})
        });

        if (informes.length > 0) {

            headersInfo.concat({ header: 'ColTotales', id: 'ColTotales' }).forEach(({ header, id }) => {
                const { pago, total } = totales.rowTotales[id];

                totales.rowPerc[id] = (pago / total) * 100;
            });
        }

        return totales;
    };

    const informesChecked = informes || [];

    const extraInfo = desglose === desgloseTipos.Detallado ?
        getDetalladoTotalesYPagosActual(informesChecked) : getConcentradoTotales(informesChecked, enabledHeaders);

    const pagedInformes = paginadorFilter(informesChecked, paginadorInfo.page, paginadorInfo.elementsPerPage);

    return (
        <div
            id="informes-tables"
            style={{
                display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#FFF", zIndex: 0,
                maxHeight: tablesMaxHeight || "auto",
                transition: "all 300ms ease-in-out",
            }}
        >
            {informes !== null ?
                <>
                    <div className="column full informes-selector">
                        {desglose === desgloseTipos.Detallado ?
                            <DetalladoTable
                                informes={pagedInformes}
                                pagosFechas={pagosFechas}
                                tableSettings={tableSettings}
                                extraInfo={extraInfo}
                            />
                            : <ConcentradoTable
                                informes={pagedInformes}
                                tableSettings={tableSettings}
                                enabledHeaders={enabledHeaders}
                                extraInfo={extraInfo}
                            />
                        }
                    </div>
                    <PaginadorParent
                        data={informes}
                        initalElementsPerPage={50}
                        onPagedDataChange={onPagedDataChanged}
                    />
                </>
                :
                <div className='row full justify-center align-center' style={{ flex: 1 }}>
                    <h3>Sin documentos en busqueda</h3>
                </div>
            }
        </div>
    )
};

const DetalladoTable = (props) => {

    const { informes, tableSettings, extraInfo } = props;
    const { colTotales, pagosActual } = extraInfo;
    const { anclarFilas } = tableSettings;

    const [activeDocumentoPagos, setActiveDocumentoPagos] = useState({ index: -1, hovered: false });
    const [parentRect, setParentRect] = useState({});

    const currentPagos = informes[activeDocumentoPagos.index]?.pagos || [];

    const enableAnclarFilas = anclarFilas ? "anclar-filas-totales" : "";

    const tableClassName = `detallados-table ${enableAnclarFilas}`;

    const textAlignCenter = { textAlign: "center" };
    const textAlignLeft = { textAlign: "left" };

    const onPagoInfoEnter = (e, index) => {

        if(index !== activeDocumentoPagos.index) {
            const bounds =  e.target.getBoundingClientRect();
            setParentRect(bounds);
        };

        setActiveDocumentoPagos({ index, hovered: true });
    };

    const onPagoInfoLeave = () => 
        setActiveDocumentoPagos(prevPagos => { return { ...prevPagos, hovered: false }});

    return (
        <>
            <table className={tableClassName}>
                <thead>
                    <tr>
                        <th>Vivienda</th>
                        <th>N° Folio</th>
                        <th>Producto</th>
                        <th style={textAlignCenter}>Mes</th>
                        <th style={textAlignCenter}>Importe</th>
                        <th style={textAlignCenter}>Descuento</th>
                        <th style={textAlignCenter}>Condonado</th>
                        <th style={textAlignCenter}>Recargos</th>
                        <th style={textAlignCenter}>Pago</th>
                        <th style={textAlignCenter}>Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    {informes.length > 0 ?
                        <>
                            {informes.map((documento, index) => 
                                <>
                                    <tr
                                        key={documento.id_docCobro}
                                    >
                                        <td style={textAlignLeft}>{documento.vivienda}</td>
                                        <td style={textAlignLeft}>{documento.num_folio}</td>
                                        <td style={textAlignLeft}>{documento.producto}</td>
                                        <td style={textAlignCenter} >{
                                            moment(documento.mes_anyo, "MMYYYY").locale('es')
                                                .format("MMM YY").replace(".", "")}
                                        </td>
                                        <TableMoneyFormat value={documento.cargo} />
                                        <TableMoneyFormat value={documento.descuento} />
                                        <TableMoneyFormat value={documento.condonacion} />
                                        <TableMoneyFormat value={documento.recargo} />
                                        <td className="text-right font-mini" >
                                            <div className='row' style={{ justifyContent: "flex-end", gap: 4 }} >
                                                <MoneyFormat value={pagosActual[index]} />
                                                <button
                                                    className="btn-info"
                                                    type="button"
                                                    onPointerEnter={(e) => onPagoInfoEnter(e, index)}
                                                    onPointerLeave={onPagoInfoLeave}
                                                >
                                                    <i className="font-small fas fa-info-circle" style={{ display: "flex", alignSelf: "center" }} />
                                                </button>
                                            </div>
                                        </td>
                                        <TableMoneyFormat value={documento.saldo} />
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td colSpan={4} style={textAlignLeft}>Totales</td>
                                <TableMoneyFormat value={colTotales.Importes} />
                                <TableMoneyFormat value={colTotales.Descuentos} />
                                <TableMoneyFormat value={colTotales.Condonados} />
                                <TableMoneyFormat value={colTotales.Recargos} />
                                <TableMoneyFormat value={colTotales.Pagados} />
                                <TableMoneyFormat value={colTotales.Saldos} />
                            </tr>
                        </>
                        :
                        <tr className='empty-data-row'>
                            <td colSpan={10}
                                style={{ border: "unset", padding: 0 }}
                            >
                                <div >
                                    <h3>Sin documentos en filtrado</h3>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <FechaPagosModal
                pagos={currentPagos}
                open={activeDocumentoPagos.hovered}
                parentRect={parentRect}
            />
        </>
    )
};

const FechaPagosModal = (props) => {

    const { pagos, open, parentRect } = props;

    const modalRef = useRef();

    const [currentPagos, setCurrentPagos] = useState(pagos);
    const [modalSettings, setModalSettings] = useState({ heightOffset: 0, widthOffset: 0 });

    if(currentPagos !== pagos && pagos) {
        setCurrentPagos(pagos);
    }

    const sizeObserverHandler = (entries) => {
        if(entries[0]) {
            const { blockSize: heightOffset, inlineSize } = entries[0].borderBoxSize[0];
            const widthOffset = inlineSize / 2;
            setModalSettings({ heightOffset, widthOffset });
        }
    };
    
    useEffect(() => {

        const observer = new ResizeObserver(sizeObserverHandler);
        observer.observe(modalRef.current, { box: "border-box" });

        return () => observer.disconnect();

    }, []);

    const modal = <div
        ref={modalRef}
        className='fecha-pagos-modal column'
        style={{
            zIndex: 2, top: parentRect.top - modalSettings.heightOffset - 2, 
            left: parentRect.left - modalSettings.widthOffset + (parentRect.width / 2),
            pointerEvents: "none",
            opacity: open ? 1 : 0,
        }}
    >
        {currentPagos.length > 0 ?
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Pago</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPagos.map(({ fecha, pago }, index) => (
                        <tr key={index}>
                            <td
                                className='font-mini text-right'
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {moment(fecha).format("DD/MM/YYYY")}
                            </td>
                            <TableMoneyFormat value={pago} />
                        </tr>
                    ))}
                </tbody>
            </table>
            :
            <div className='full align-center' style={{ padding: "0.1rem 0.6rem" }}>
                <b style={{ fontSize: "0.9rem", color: "white"}}>Sin Pagos</b>
            </div>
        }
    </div>;

    return createPortal(modal, document.body);
};

const ConcentradoTable = (props) => {

    const { informes, tableSettings, enabledHeaders, extraInfo } = props;
    const { rowTotales, rowPerc, colTotales } = extraInfo;
    const { anclarColumnas, anclarFilas, desglosarTotales } = tableSettings;

    const tableRef = useRef();

    const enableAnclarColumnas = anclarColumnas ? "anclar-columnas-totales" : "";
    const enableAnclarFilas = anclarFilas ? "anclar-filas-totales" : "";
    const enableDesglosarTotales = desglosarTotales ? 'desglosar-totales' : 'no-desglosar-totales';

    const tableClassName = `concentrados-table ${enableAnclarColumnas} ${enableAnclarFilas} 
                            ${enableDesglosarTotales}`;

    useEffect(() => {

        if(enableAnclarColumnas === "") return;

        const tableElement = tableRef.current;

        const rowElement = tableElement.querySelector('tbody tr');
        const lastTds = Array.from(rowElement.children).slice(-5);

        let widthAcum = +Number(lastTds.pop().getBoundingClientRect().width).toFixed(2);
        
        lastTds.reverse().forEach((td, index) => {
            const width = +Number(td.getBoundingClientRect().width).toFixed(2)
            const property = '--offset-' + (index + 1);
            const value = widthAcum + "px";
            tableElement.style.setProperty(property, value)
            widthAcum += width;
        });

    });

    const colSpan = desglosarTotales ? 5 : 2;

    const HeaderTdGroup = () => {

        const style = { fontStyle: "italic", fontWeight: "500" };

        return desglosarTotales ?
            <>
                <td style={style}>Importe</td>
                <td style={style}>Descuento</td>
                <td style={style}>Condonado</td>
                <td style={style}>Recargo</td>
                <td style={style}>Pago</td>
            </>
            :
            <>
                <td style={style}>Total</td>
                <td style={style}>Pago</td>
            </>
    };

    const TotalesTdGroup = ({ totales }) => {

        const { cargo, descuento, condonacion, recargo, total, pago } = totales;

        return desglosarTotales ? 
            <>
                <TableMoneyFormat value={cargo} />
                <TableMoneyFormat value={descuento} />
                <TableMoneyFormat value={condonacion} />
                <TableMoneyFormat value={recargo} />
                <TableMoneyFormat value={pago} />
            </>
            :
            <>
                <TableMoneyFormat value={total} />
                <TableMoneyFormat value={pago} />
            </>
    };

    return (
        <table ref={tableRef} className={tableClassName}>
            <thead>
                <tr>
                    <th style={{ textAlign: "left" }}>Vivienda</th>
                    {enabledHeaders.map(({ header }, index) => (
                        <th colSpan={colSpan} key={index}>{header}</th>
                    ))}
                    <th colSpan={colSpan}>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    {enabledHeaders.map((info, index) => (
                        <HeaderTdGroup />
                    ))}
                    <HeaderTdGroup />
                </tr>
                {informes.length > 0 ?
                    <>
                        {informes.map(({ productos, id_vivienda, vivienda }) => (
                            <tr key={id_vivienda}>
                                <td>{vivienda}</td>
                                {enabledHeaders.map(({ id }, index) => {

                                    const producto = productos.find(({ id_producto }) => id_producto === id);
                                    return <TotalesTdGroup key={index} totales={producto} />
                                })}
                                <TotalesTdGroup totales={colTotales[id_vivienda]} />
                            </tr>
                        ))}
                        <tr>
                            <td>Totales</td>
                            {enabledHeaders.map(({ id }, index) => (
                                <TotalesTdGroup key={index} totales={rowTotales[id]} />
                            ))}
                            <TotalesTdGroup totales={rowTotales['ColTotales']} />
                        </tr>
                        <tr>
                            <td >Cobranza</td>
                            {enabledHeaders.map(({ id }) => (
                                <TablePercentageFormat value={rowPerc[id]} colSpan={colSpan} />
                            ))}
                            <TablePercentageFormat value={rowPerc['ColTotales']} colSpan={colSpan} />
                        </tr>
                    </>
                    :
                    <tr className='empty-data-row'>
                        <td colSpan={((enabledHeaders.length + 1) * colSpan) + 1}
                            style={{ border: "unset" }}
                        >
                            <div >
                                <h3>Sin documentos en filtrado</h3>
                            </div>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    )
};

const MoneyFormat = ({ value }) =>
    <NumberFormat
        className="font-mini"
        value={value}
        displayType={"text"}        
        thousandSeparator={true}
        prefix="$"
        decimalScale={2}
        fixedDecimalScale={true}
    />;

const TableMoneyFormat = ({ value }) => {

    return <td className="text-right font-mini">
        <MoneyFormat value={value}/>
    </td>
};

const TablePercentageFormat = ({ value, colSpan }) => {

    return <td className="font-mini" colSpan={colSpan}>
        <NumberFormat
            className="font-mini"
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            suffix=' %'
            decimalScale={1}
            fixedDecimalScale={false}
        />
    </td>
};
